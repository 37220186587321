import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=d78451c2"
import script from "./Home.vue?vue&type=script&lang=js"
export * from "./Home.vue?vue&type=script&lang=js"
import style0 from "./Home.vue?vue&type=style&index=0&id=d78451c2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/locales/home.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2FHome.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/articles-card-content.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2FHome.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/locales/card-content-services.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2FHome.vue&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@/locales/card-home-content-clinic.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2FHome.vue&external"
if (typeof block3 === 'function') block3(component)

export default component.exports