export const articlesCardContentFilterMixin = {
  computed: {
    filteredArticlesCardContent () {
      let articlesCardContent = []
      switch (this.$i18n.locale) {
        case 'en':
          articlesCardContent = this.$i18n.messages.en.content
          break
        case 'ru':
          articlesCardContent = this.$i18n.messages.ru.content
          break
        default:
          articlesCardContent = this.$i18n.messages.fr.content
      }
      return Object.values(articlesCardContent)
        .filter(articleCardContent => this.articlesCardContentId.indexOf(articleCardContent.id) !== -1)
    }
  }
}
