export const cardHomeContentClinicMixin = {
  computed: {
    cardHomeContentClinic () {
      let cardHomeContentClinic = []
      switch (this.$i18n.locale) {
        case 'en':
          cardHomeContentClinic = this.$i18n.messages.en.clinicCardContent
          break
        case 'ru':
          cardHomeContentClinic = this.$i18n.messages.ru.clinicCardContent
          break
        default:
          cardHomeContentClinic = this.$i18n.messages.fr.clinicCardContent
      }
      return Object.values(cardHomeContentClinic)
        .filter(articleCardContent => articleCardContent)
    }
  }
}
