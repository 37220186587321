<i18n src="@/locales/home.json" />
<i18n src="@/locales/articles-card-content.json" />
<i18n src="@/locales/card-content-services.json" />
<i18n src="@/locales/card-home-content-clinic.json" />
<template>
  <div>
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <b-message>
      <p>
        {{ $t('header_message') }}
      </p>
    </b-message>
    <ImanH2 id="nos-services">
      {{ $t('h2_services') }}
    </ImanH2>
    <section class="section">
      <div class="columns is-gapless is-multiline">
        <div
          v-for="contentService in cardContentService"
          :key="contentService.id"
          class="column is-half-tablet is-one-quarter-widescreen is-one-quarter-fullhd"
        >
          <ImanRoundedPictureBox
            :content="contentService"
            :service-class="serviceClass"
          >
            {{ $t('more_info_1') }}
          </ImanRoundedPictureBox>
        </div>
      </div>
    </section>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="firstSection.img">
        <ImanH3>{{ $t('h3_anveli') }}</ImanH3>
        <p>
          {{ $t('anveli_content_1') }}
        </p>
        <p>
          {{ $t('anveli_content_2') }}
        </p>
        <p>
          {{ $t('anveli_content_3') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="secondSection.img">
        <ImanH3>{{ $t('h3_basal') }}</ImanH3>
        <p>
          {{ $t('basal_content') }}
        </p>
        <ul>
          <li>{{ $t('basal_li_1') }}</li>
          <li>{{ $t('basal_li_2') }}</li>
          <li>{{ $t('basal_li_3') }}</li>
          <li>{{ $t('basal_li_4') }}</li>
        </ul>
        <ImanMoreInfo :path="$t('more_info_path_2')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
      <ImanHomeSection :img="thirdSection.img">
        <ImanH3>{{ $t('h3_care') }}</ImanH3>
        <p>
          {{ $t('care_content_1') }}
        </p>
        <p>
          {{ $t('care_content_2') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_3')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_clinic') }}</ImanH2>
    <ImanArticle>
      <b-message
        type="is-info"
      >
        <p>
          {{ $t('clinic_message_content') }}
        </p>
      </b-message>
    </ImanArticle>
    <section class="section">
      <div class="columns is-centered">
        <div
          v-for="cardContent in cardHomeContentClinic"
          :key="cardContent.id"
          class="column is-2"
        >
          <ImanCountryCard
            :content="cardContent"
            :country-card-class="countryOverlayClass"
          />
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_partner') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanPartnerSection
        :carousels-partner="partnerContent"
      />
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
    <ImanH2>{{ $t('h2_contact') }}</ImanH2>
    <section class="section">
      <div class="columns has-text-centered">
        <div class="column is-4">
          <div class="content">
            <p>
              <span class="icon has-text-primary is-medium">
                <i class="mdi mdi-map-marker mdi-48px" />
              </span>
            </p>
            <p>
              <span class="has-text-primary is-size-5">{{ $t('contact_content_1') }}</span>
            </p>
            <p>
              <a :href="$t('serbia_clinic_url')">Serbia</a>|<a :href="$t('montenegro_clinic_url')">Montenegro</a><br>
              <a :href="$t('swiss_clinic_url')">Switzerland</a>|<a
                :href="$t('sofia_clinic_url')"
              >Bulgaria</a>
            </p>
          </div>
        </div>
        <div class="column">
          <div class="content">
            <p>
              <span class="icon has-text-primary is-medium">
                <i class="mdi mdi-phone-in-talk mdi-48px" />
              </span>
            </p>
            <p>
              <span class="has-text-primary is-size-5">{{ $t('contact_content_2') }}</span>
            </p>
            <p class="phone">
              +33(0)9 88 99 99 33 <br>
              +359 (0) 249 175 88
            </p>
          </div>
        </div>
        <div class="column">
          <div class="content">
            <p>
              <span class="icon has-text-primary is-medium">
                <i class="mdi mdi-email-open-outline mdi-48px" />
              </span>
            </p>
            <span class="has-text-primary is-size-5">{{ $t('contact_content_3') }}</span>
            <p class="email">
              expert@anveli.info
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { content as partnerContent } from '@/data/Partner-home-content.json'
import ImanArticleSection from "@/components/ImanArticleSection";
import ImanRoundedPictureBox from "@/components/ImanRoundedPictureBox";
import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
import ImanCountryCard from "@/components/ImanCountryCard";
import {cardHomeContentClinicMixin} from "@/mixins/card-home-content-clinic-mixin";
import {cardContentServicesMixin} from "@/mixins/card-content-services-mixin";
import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: 'Home',
  components: {
    ImanArticleSection,
    ImanRoundedPictureBox,
    ImanCountryCard
  },
  mixins: [
    articlesCardContentFilterMixin,
    cardHomeContentClinicMixin,
    cardContentServicesMixin,
    metaMixin
  ],
  data () {
    return {
      mainBackgroundClass: 'none-background',
      overlayClass: 'overlay-half-circle',
      countryOverlayClass: {
        overlay:'overlay show-overlay',
        element: 'element show-element'
      },
      serviceClass: {
        overlay: '.overlay-half-circle .show-overlay-half-circle',
        element: 'element show-element'
      },
      partnerContent,
      articlesCardContentId: [20, 30, 41, 53],
      firstSection: {
        img: {
          path: 'home/text-box/textbox_picture1_web.jpg',
          position: 'right',
          alt: 'implantologie basal, implant dentaire immédiat sans greffe osseuse'
        }
      },
      secondSection: {
        img: {
          path: 'home/text-box/textbox_picture2_web.jpg',
          position: 'right',
          alt: 'pour la pose d\'implant basal nouvelle génération'
        }
      },
      thirdSection: {
        img: {
          path: 'home/text-box/textbox_picture3_web.jpg',
          position: 'right',
          alt: 'pour la pose de couronnes dentaires, facettes et dents esthétiques'
        }
      }
    }
  },
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../assets/img/thumbnail/MINIATURE_3.0.png')
    }
  },
  created() {
    window.addEventListener('scroll', this.whenScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.whenScroll)
  },
  methods: {
    whenScroll() {
      if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
        this.mainBackgroundClass = 'none-background'
        this.serviceClass = {
          overlay: 'overlay-half-circle',
          element: 'element'
        }
        this.countryOverlayClass = {
          overlay:'overlay',
          element: 'element'
        }
      }
      if (document.body.scrollTop > 670 || document.documentElement.scrollTop > 670) {
        this.serviceClass = {
          overlay: 'overlay-half-circle show-overlay-half-circle',
          element: 'element show-element'
        }
      }

      if (document.body.scrollTop > 1135 || document.documentElement.scrollTop > 1135) {
        this.serviceClass = {
          overlay: 'overlay-half-circle',
          element: 'element'
        }
        this.mainBackgroundClass = 'fixed-background'
      }

      if (document.body.scrollTop > 2440 || document.documentElement.scrollTop > 2440) {
        this.countryOverlayClass = {
          overlay:'overlay show-overlay',
          element: 'element show-element'
        }
      }

      if (document.body.scrollTop > 2800 || document.documentElement.scrollTop > 2800) {
        this.mainBackgroundClass = 'none-background'
        this.countryOverlayClass = {
          overlay:'overlay show-overlay',
          element: 'element show-element'
        }
      }

      if (document.body.scrollTop > 3900 || document.documentElement.scrollTop > 3900) {
        this.mainBackgroundClass = 'fixed-background'
      }
    }
  }
}
</script>

<style lang="scss">
  .map {
    margin-top: 1em;
    width: 100%;
    min-height: 400px;
  }

  #nos-services + .section {
    @media only screen and (max-width: 768px) {
      padding: 1rem;
    }
  }

  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../assets/img/clinic/h1-clinic-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../assets/img/clinic/h1-clinic-d.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
      /* smartphone en mode portait */
      background-image: url("../assets/img/clinic/h1-mobile-clinic-m.jpg");
    }
  }
</style>
