export const cardContentServicesMixin = {
  computed: {
    cardContentService () {
      let cardContentService = []
      switch (this.$i18n.locale) {
        case 'en':
          cardContentService = this.$i18n.messages.en.cardServicesContent
          break
        case 'ru':
          cardContentService = this.$i18n.messages.ru.cardServicesContent
          break
        default:
          cardContentService = this.$i18n.messages.fr.cardServicesContent
      }
      return Object.values(cardContentService)
        .filter(articleCardContent => articleCardContent)
    }
  }
}
